import React from "react"

// import SEO from "react-seo-component"
import { SEO, Link } from "~components"
// import { Box } from "~styled"
import { useSiteMetadata } from "~hooks/use-site-metadata"
import { usePalette } from "react-palette"
import { Col, Container, Row } from "react-bootstrap"
import CategoryStyle from "./categoryStyle"
import StyledCard from "~components/PostCard"

// import { StaticImage as Img } from "gatsby-plugin-image"
// import { Link } from "~components"

import { AdsWrapper } from "~components/Core"

import AdIntro from "~components/AdIntro"

import CtaSection from "~components/Cta"
import FooterBlock from "~components/FooterBlock"
import FooterSimple from "~components/FooterSimple"

// import { Images } from "~data"
// import { Config } from "~data"

import HeroSection from "~components/Hero"
import SectionTitle from "~components/SectionTitle"
// import HeaderButton from "~sections/marketing/Header"

// import HeroSection from "~sections/homepage/Hero"
// import AboutSection from "~sections/agency/About"
// import About from "~components/About"
// import SectionTitle from "~components/About"

// import ServiceSection from "~sections/homepage/Service"
// import FeatureSection from "~sections/homepage/Features"
// import ContentSectionLeft from "~sections/homepage/ContentLeft"
// import ContentSectionTwo from "~sections/homepage/ContentTwo"
// import TestimonialSection from "~sections/app/Testimonial"
// import TreatmentsSection from "~sections/app/Treatments"

// import CounterSection from "~sections/homepage/Counter"
// import ActionSection from "~components/Action/ActionSection"
// import TeamSection from "~sections/digital/Team"

// mobile-sticky-enable

const header = {
  headerClasses:
    "site-header site-header--menu-end dark-header site-header--sticky position-relative site-header--menu-sep",
  containerFluid: false,
  darkLogo: false,
  buttonBlock: (
    <Link
      title={"Gift Cards"}
      className="btn btn-outline-secondary rounded-pill py-3 ms-auto d-none d-xs-inline-flex"
      link="https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ==">
      <span>
        <i className="fa fa-gift"></i>
        {"  "}Gift Cards
      </span>
    </Link>
  ),
}

export default function BlogPage({ pageContext }) {
  const siteMetadata = useSiteMetadata()
  const { slug, data, category, title, description } = pageContext
  const { title: siteTitle, siteUrl, siteLanguage, siteLocale } = useSiteMetadata()
  // // Sort by date in descending order
  // data.sort((a, b) => {
  //   return b.node.frontmatter.date - a.node.frontmatter.date
  // })
  // console.log(`data: `, data)

  React.useEffect(() => {}, [])

  return (
    <>
      {/* <Helmet>
        <meta property="og:image" content={domain + ogImage.path} />
        <meta property="og:image" content={ogImage.path} />
        <meta property="og:image:width" content={ogImage.size.width} />
        <meta property="og:image:height" content={ogImage.size.height} />
      </Helmet> */}

      <AdsWrapper headerConfig={header} adsPage>
        <SEO
          titleTemplate={`${siteTitle}`}
          titleSeparator={`-`}
          title={`${category}`}
          description={`${description || ""}`}
          type={`${siteMetadata.type}`}
          location={`${siteUrl}${slug}`}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          indexed="true"
        />
        <div className="py-6"></div>
        <div className="py-6"></div>
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col col="12" className="col-12 text-center">
              <CategoryStyle.Box mb="30px" mbLG="50px">
                <CategoryStyle.Title as="h1">{category}</CategoryStyle.Title>
              </CategoryStyle.Box>
            </Col>
          </Row>
          <Row className="justify-content-center blog-container">
            {data.map(({ node } = item, index) => {
              let { frontmatter, fields } = node
              return (
                <Col className="col-md-6 col-xs-12 col-12" key={"related-" + index}>
                  <StyledCard
                    to={`/blog${fields.slug}`}
                    image={frontmatter.heroImage}
                    title={frontmatter.title || frontmatter.heroTitle}
                    description={frontmatter.description || frontmatter.heroDescription}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
        <div className="py-6"></div>
        <FooterBlock />
        <FooterSimple />
      </AdsWrapper>
    </>
  )
}
