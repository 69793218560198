import React from "react"
import { Link } from "~components"

import Card from "./style"
export default function PostCard({ to, title, description, text, image }) {
  return (
    <Card as={Link} link={to ? to : "/"} className={`w-100`} style={{ borderRadius: "12px", overflow: "hidden" }}>
      <Card.Image backgroundImage={image?.childImageSharp.gatsbyImageData.images.fallback.src} />
      <Card.Content>
        <Card.InnerBlock backgroundColor="#fff">
          <Card.Title as="h2" fontColor="#222">
            {title}
          </Card.Title>
          <Card.Description fontColor="#222">{description?.slice(0, 110).concat("...")}</Card.Description>
        </Card.InnerBlock>
      </Card.Content>
    </Card>
  )
}
